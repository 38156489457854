import { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Group, 
  Stack, 
  Text, 
  rem 
} from '@mantine/core';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useForm } from '@mantine/form';
import { useTranslation } from '@/hooks';
import { VIEWS } from '../interfaces';
import { useRegistration } from '@/hooks/useRegistration';
import { useUser } from '@/hooks/useUser';
import { errorNotification } from '@/util';
import { initiateCardSession } from '@/lib/initiateCardSession';

interface FormValues {
  type: string;
  name: string;
}

interface Props {
  onViewChange: (view: VIEWS) => void;
}

//test key
const stripePromise = loadStripe(
  'pk_test_51NLASrBMlgaw8gEgWezX6Q9sg3wfGNSU6McJzKJuD35Lff7kCfLh4YmxNOt1WIuiQZ76ikXN77Ofb2Wx3YfwvYDR00MQMvARO7',
);

export const PaymentDetails = ({ onViewChange }: Props) => {
  const admin = useRegistration((state) => state.user);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    if (admin && admin.stripeId) {
      initiateCardSession({ stripe_id: admin.stripeId })
        .then((data) => setClientSecret(data.client_secret));
    }  }, [admin]);

  const options = { clientSecret };

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const clearRegistrationData = useRegistration((state) => state.clear);

  const form = useForm<FormValues>({
    initialValues: {
      type: 'individual',
      name: '',
    },
  });

  const handleSave = async (values: FormValues) => {
    const { name, type } = values;
    setLoading(true);

    try {
      clearRegistrationData();
      onViewChange(VIEWS.LOGIN);
    } catch (error) {
      console.error(error);
      errorNotification('Failed to update user data');
    }

    setLoading(false);
  };

  return (
    <Box ml={rem(34)}>
      <form onSubmit={form.onSubmit(handleSave)}>
        <Text fz={rem(35)} fw={600}>
          {t('payment_title')}
        </Text>
        <Text fz={rem(18)} mb={rem(50)}>
          {t('payment_text')}
        </Text>
        <Stack mt={rem(70)} justify="space-between">
          <div id="checkout">
            {clientSecret && (
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            )}
          </div>
          <Group justify="space-between" gap={rem(30)} mt={rem(32)}>
            <Button type="submit" loading={loading}>
              {t('continue')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Box>
  );
};
