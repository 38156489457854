import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

interface Body {
  username?: string;
  user_type: string;
  image_url: string;
  personal_code?: string;
  is_vat_payer?: boolean;
  business_activity?: string; 
}

export const updateSubAdmin = async (adminId: string, body: Body) => {
  try {
    const resp = await httpClient().put<Item>(
      `/subadmin/update/${adminId}/`,
      body,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('User data updated');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to update user data');
    console.error(error);
  }
};
