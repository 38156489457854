import { useState } from 'react';
import { Flex, rem, Box, Loader, Text } from '@mantine/core';
import useSWR from 'swr';
import Image from 'next/image';
import { useParams, useRouter } from 'next/navigation';
import { getItemImage } from '@/lib/getItemImages';
import classes from './itemImage.module.css';
import ToolDefaultImage from '@/public/images/tool-image.svg';

interface Props {
  id: string;
  name: string;
  urls: string[];
}

export const ItemImage = ({ id, name, urls }: Props) => {
  const [hasError, setHasError] = useState(false);
  const router = useRouter();
  const params = useParams();

  const { data: images, isLoading } = useSWR(`/tool/${id}/image`, () =>
    getItemImage(urls),
  );

  return (
    <Flex
      className={classes.container}
      align="center"
      gap={rem(10)}
      onClick={() => router.push(`/${params.lang}/items/${id}`)}
    >
      <Box className={classes.imageWrapper}>
        {images && !hasError && (
          <Image
            className={classes.loadedImage}
            src={images[0]}
            fill
            alt={name}
            onError={() => setHasError(true)}
            sizes="(min-width: 300px) 30px"
          />
        )}
        {isLoading && <Loader size={30} />}
        {(!images || hasError) && !isLoading && (
          <Image
            src={ToolDefaultImage}
            height={30}
            width={30}
            alt="Default tool image"
          />
        )}
      </Box>
      <Text fz={rem(14)}>{name}</Text>
    </Flex>
  );
};
