import { Item } from '@/interfaces';
import { errorNotification, successNotification } from '@/util';
import { OK_STATUSES, httpClient } from '@/util/httpClient';

interface Body {
  name: string;
  company_type: string;
  registration_number: string;
  legal_address?: string;
  vat_number?: string;
}

export const updateSubCompany = async (companyId: string, body: Body) => {
  try {
    const resp = await httpClient().put<Item>(
      `/subcompany/update/${companyId}/`,
      body,
    );

    if (OK_STATUSES.includes(resp.status)) {
      successNotification('Company data updated');
    }
    console.error(resp);
  } catch (error) {
    errorNotification('Failed to update company data');
    console.error(error);
  }
};
