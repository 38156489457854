import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/Admins/Admins.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/Auth/Auth.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/Categories/Categories.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/Home/Home.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/Items/Items.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/Profile/Profile.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/RentalPoint/RentailPoint.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/RentalPoints/RentalPoints.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/SubCompanies/Legal/LegalList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/SubCompanies/Private/PrivateList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/Subscriptions/Subscriptions.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/handt-admin-v2/views/Users/Users.tsx")