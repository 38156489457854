// TODO: move to BE, should not be hardcoded
export const MONTHLY_PRICES: { [key: string]: number } = {
  'S (64 x 38 x 9 cm)': 10,
  'M (64 x 38 x 19 cm)': 25,
  'L (64 x 38 x 39 cm)': 50,
  'XL (100 x 200 x 150 cm)': 50,
  '-': 0,
};

export const YEARLY_PRICES: { [key: string]: number } = {
  'S (64 x 38 x 9 cm)': 5,
  'M (64 x 38 x 19 cm)': 12.5,
  'L (64 x 38 x 39 cm)': 25,
  'XL (100 x 200 x 150 cm)': 25,
  '-': 0,
};

export const OFFLINE_STATUSES = ['MAINTENANCE', 'PARTMAINTENANCE', 'DISABLED'];
